import * as React from 'react';
import Layout from '../../components/Layout';
import PageHeader from '../../components/PageHeader';
import PageTitle from '../../components/PageTitle';
import Section from '../../components/Section';
import OffsetCardGrid from '../../components/OffsetCardGrid';
import {graphql} from 'gatsby';

const CosmeticsPage = ({data}) => {
    const {contentfulBasicPageSection, contentfulProductDetailsSection} = data;

    return (
        <>
            <Layout>
                <main>
                    <PageHeader>
                        <PageTitle>
                            <div>{contentfulBasicPageSection.subtitle}</div>
                            <h1>{contentfulBasicPageSection.title}</h1>
                        </PageTitle>
                    </PageHeader>
                    <Section hasMaxWidth isCosmeticsPage>
                        <OffsetCardGrid cards={contentfulProductDetailsSection.elements} body={contentfulBasicPageSection.body}/>
                    </Section>
                </main>
            </Layout>
        </>
    )
}

export default CosmeticsPage;

export function Head({data}) {
    const {contentfulBasicPageSection} = data;
    return (
        <title>{contentfulBasicPageSection.title} | Derschlag</title>
    )
}

export const query = graphql`
  query ($locale: String!) {
    contentfulBasicPageSection(
      contentful_id: {eq: "2PevcORIlSAMTTRiUobEFy"}
      node_locale: {eq: $locale}
    ) {
      ... BasicPageFields
    }
    contentfulProductDetailsSection(contentful_id: {eq: "3KX9lAvKzE6kIoNktyutli"}, node_locale: {eq: $locale}) {
      ... ProductDetailsFields
    }
  }
`;
